<template>
	<div class="pricenew_bg">
		<div class="pa-0 wrapper">
			<v-container
				fluid
				class="pa-0"
			>
				<HomeInteriorPriceInputsnew
					:reset="dataReset"
					@submit="submit"
				/>
			</v-container>
		</div>
	</div>
</template>

<script>
import common from '@/mixins/common'
import ad_naver from '@/mixins/ad/naver'

export default {
	name: 'HomeInteriorPrice',
	metaInfo: {
		title: '견적 내기 | 동화자연마루',
		meta: [
			{
				vmid: 'title',
				property: 'og:title',
				content: '견적 내기 | 동화자연마루',
			},
			{
				vmid: 'keywords',
				property: 'keywords',
				content: '견적내기, 바닥재 시공, 바닥재 견적, 바닥재 시공 상세 견적, 인테리어 시공, 인테리어 시세, 인테리어 견적',
			},
			{
				vmid: 'description',
				property: 'og:description',
				content: '몇 가지 항목만 체크하고 바닥재 시공 상세 견적을 받아보세요.',
			},
			{
				vmid: 'url',
				property: 'og:url',
				content: `${process.env.VUE_APP_BASIC_SERVER}/interior/price`,
			},
			{
				vmid: 'og:image',
				property: 'og:image',
				content: 'https://www.greendongwha.com/main.png',
			},
		],
	},
	mixins: [common, ad_naver],
	data: () => ({
		callBill: false,
		dataReset: false,
	}),
	computed: {},
	mounted() {
		// ad_naver page
		this.ad_naver_changeScript(2, 1)
	},
	destroyed() {},
	methods: {
		priceDataReset() {
			this.common_scrollToTop()
			this.callBill = false
			this.dataReset = true
		},
		submit() {
			this.callBill = true
			this.dataReset = false
		},
	},
}
</script>

<style scoped lang="scss">
.pricenew_bg {
	background-color: $color_gray_1;
	height: 100%;
	&::after {
		content: '';
		background: #eef8f3;
		width: 100%;
		height: 190px;
		position: absolute;
		top: 116px;
		z-index: 0;
	}
}

.price_banner {
	position: relative;
	width: 100%;
	height: 140px;
	background: url('../../../assets/img/home/interior/img_banner_interior.jpg') no-repeat;
	background-position: center;
	.text {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		.v-icon {
			margin-bottom: 5px;
			color: #000;
		}
		p {
			min-width: 220px;
			font-family: 'NotoSansKR-Regular';
			font-size: 18px;
			color: #333;
			word-break: keep-all;
		}
	}
}

@media all and (min-width: 981px) and (max-width: 1280px) {
}
@media all and (min-width: 769px) and (max-width: 980px) {
	.pricenew_bg {
		&::after {
			content: '';
			background: #eef8f3;
			width: 100%;
			height: 190px;
			position: absolute;
			top: 0;
			z-index: 0;
		}
	}
}
@media all and (min-width: 380px) and (max-width: 768px) {
	.price_banner {
		height: 100px !important;
		.text {
			p {
				font-size: 15px !important;
			}
		}
	}

	.pricenew_bg {
		&::after {
			content: '';
			background: #eef8f3;
			width: 100%;
			height: 190px;
			position: absolute;
			top: 0;
			z-index: 0;
		}
	}
}
@media all and (max-width: 470px) {
	.pricenew_bg {
		padding: 48px 0 0px !important;
		&::after {
			content: '';
			background: #eef8f3;
			width: 100%;
			height: 190px;
			position: absolute;
			top: 0;
			z-index: 0;
		}
	}
}

@media all and (max-width: 380px) {
	.price_banner {
		height: 100px !important;
		.text {
			p {
				font-size: 15px !important;
			}
		}
	}
}
</style>
